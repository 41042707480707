/*
 *
 * MarketPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import { ActionTypes } from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const applySearch = (data: any) => action(ActionTypes.APPLY_SEARCH, data);

export const description = createAsyncAction(
  ActionTypes.FETCH_DESCRIPTION_REQUEST,
  ActionTypes.FETCH_DESCRIPTION_SUCCESS,
  ActionTypes.FETCH_DESCRIPTION_FAILURE,
)<void, string, Error>();

export const products = createAsyncAction(
  ActionTypes.FETCH_PRODUCTS_REQUEST,
  ActionTypes.FETCH_PRODUCTS_SUCCESS,
  ActionTypes.FETCH_PRODUCTS_FAILURE,
)<void, {}, Error>();

export const sendRequestNewProductAction = (data: any) => action(ActionTypes.SEND_REQUEST_NEW_PRODUCT, data);
