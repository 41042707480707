/*
 * MarketPage Messages
 *
 * This contains all the text for the MarketPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MarketPage';

export default defineMessages({
  mart: {
    id: `${scope}.mart`,
    defaultMessage: 'Chợ',
  },
  addToCart: {
    id: `${scope}.addToCart`,
    defaultMessage: 'Thêm vào giỏ',
  },
  notFound: {
    id: `${scope}.notFoundProduct`,
    defaultMessage: 'Rất tiếc, không tìm thấy sản phẩm phù hợp với kết quả tìm kiếm',
  },
  notFoundDescription: {
    id: `${scope}.notFoundDescription`,
    defaultMessage: 'Chúng tôi không tìm thấy sản phẩm nào',
  },
  readMore: {
    id: `${scope}.readMore`,
    defaultMessage: 'Xem thêm',
  },
  collapse: {
    id: `${scope}.collapse`,
    defaultMessage: 'Thu gọn',
  },
  backToHome: {
    id: `${scope}.backToHome`,
    defaultMessage: 'Về trang chủ',
  },
  requestSellProduct: {
    id: `${scope}.requestSellProduct`,
    defaultMessage: 'Yêu cầu bán sản phẩm',
  },
});
