import React, { Component } from 'react';
import SubCategoryNavBar from './subCategoryNavBarDesktop';
import { Viewport } from 'components/ViewportProvider';

class SubCategoryNavBarDesktop extends Component<any, any> {
  public render() {
    return <Viewport.Consumer>{() => <SubCategoryNavBar {...this.props} />}</Viewport.Consumer>;
  }
}

export default SubCategoryNavBarDesktop;