import { Input as AInput, Col, Form, Modal, Row, Checkbox } from 'antd';
import React, { FC, useState, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import styled from 'styles/styled-components';
import utilsMessages from '../../utils/messages';
import storeMessage from '../../components/StoreForm/messages';

import Input from '../../components/TrackingComponents/Input';

import translations from 'translations';
import { RouteComponentProps, RouteProps, withRouter } from 'react-router-dom';
import { parse } from 'utils/queryString';

interface IOwnProps {
  open: boolean;
  setModalStatus: (status: boolean) => void;
  sendRequestNewProduct: (payload: any) => void;
  storeId: string;
  userEmail: string;
}

const PopupRequestNewProduct = styled(Modal)`
  width: 100% !important;
  max-width: 652px;
  .ant-modal-content {
    padding: 24px !important;
  }
  .ant-modal-footer {
    display: flex !important;
    gap: 12px !important;
  }
`;

const TitleWrapper = styled.div`
  text-align: center;
  margin: 24px 0;
`;
const FormTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`;
const FormSubTitle = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 12px !important;
`;

const AntdInput = styled(AInput)<any>``;

type Props = IOwnProps & RouteProps & RouteComponentProps;

const RequestNewProductPopup: FC<Props> = (props) => {
  const { sendRequestNewProduct, open, setModalStatus, location, storeId, userEmail } = props;
  const [receiveNotiByPhone, setReceiveNotiByPhone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const formatPhoneNumber = () => {
    if (form.getFieldError('telephone').length || !form.getFieldValue('telephone')) {
      return;
    }
    const regex = /[()+\-.\s]/g;
    const phoneNumberValue = form.getFieldValue('telephone');
    form.setFieldValue('telephone', phoneNumberValue.replace(regex, ''));
  };

  useEffect(() => {
    if (location?.search) {
      const searchValue = parse(location?.search);
      form.setFieldValue('productName', searchValue?.filter?.query);
    }
  }, [location?.search, open]);

  return (
    <PopupRequestNewProduct
      open={open}
      closeIcon={false}
      okButtonProps={{ style: { width: '100%', margin: '0px', height: '40px' } }}
      cancelButtonProps={{ style: { width: '100%', margin: '0px', height: '40px' } }}
      okText={<FormattedMessage {...utilsMessages.sendRequest} />}
      cancelText={<FormattedMessage {...utilsMessages.cancel} />}
      onOk={() => form.submit()}
      onCancel={() => {
        setModalStatus(false);
        setReceiveNotiByPhone(false);
        setIsLoading(false);
        form.resetFields();
      }}
      confirmLoading={isLoading}
      destroyOnClose={true}
    >
      <TitleWrapper>
        <FormTitle>
          <FormattedMessage {...utilsMessages.requestNewProductTitle} />
        </FormTitle>
        <FormSubTitle>
          <FormattedMessage {...utilsMessages.requestNewProductSubTitle} />
        </FormSubTitle>
      </TitleWrapper>
      <Form
        form={form}
        layout="vertical"
        size="large"
        onFinish={(values) => {
          setIsLoading(true);
          sendRequestNewProduct({
            input: {
              ...values,
              storeUuid: storeId,
              accountEmail: userEmail,
              isNotifyPhoneForStock: receiveNotiByPhone,
            },
            cb: (isSuccess = false) => {
              if (isSuccess) {
                setModalStatus(false);
              }
              setIsLoading(false);
              setReceiveNotiByPhone(false);
            },
          });
        }}
      >
        <Row gutter={12}>
          <Col span={24}>
            <FormattedMessage {...utilsMessages.requestProductName}>
              {(productName: any) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: productName }}>
                  {(fieldRequired) => (
                    <FormattedMessage {...utilsMessages.requestProductNamePlaceHolder}>
                      {(productNamePlaceHolder) => (
                        <FormItem
                          name="productName"
                          colon={false}
                          required={false}
                          label={productName}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: fieldRequired,
                            },
                          ]}
                        >
                          <Input InputComponent={AntdInput} type="text" placeHolder={productNamePlaceHolder} />
                        </FormItem>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
          <Col span={24}>
            <FormattedMessage {...utilsMessages.requestProductLinkRef}>
              {(requestProductLinkRef: any) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: requestProductLinkRef }}>
                  {(fieldRequired) => (
                    <FormattedMessage {...utilsMessages.requestProductLinkRefPlaceHolder}>
                      {(requestProductLinkRefPlaceHolder) => (
                        <Form.Item
                          name="productLinkRef"
                          label={requestProductLinkRef}
                          colon={false}
                          rules={[{ required: false, message: fieldRequired }]}
                        >
                          <Input
                            InputComponent={AntdInput}
                            type="text"
                            onBlur={formatPhoneNumber}
                            placeHolder={requestProductLinkRefPlaceHolder}
                          />
                        </Form.Item>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
          <Col span={24}>
            <FormattedMessage {...utilsMessages.requestProductPhoneNumber}>
              {(requestProductPhoneNumber: any) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: requestProductPhoneNumber }}>
                  {(fieldRequired) => (
                    <FormattedMessage {...utilsMessages.requestProductPhoneNumberPlaceHolder}>
                      {(phonePlaceHolder) => (
                        <Form.Item
                          name="phoneNumber"
                          label={requestProductPhoneNumber}
                          colon={false}
                          rules={[
                            { required: false, message: fieldRequired },
                            {
                              pattern: /^(?=(?:[()+\-.\s]*\d){9,12}$)[()+\-.,\s\d]*$/,
                              message: translations(storeMessage.invalidPhoneNumber),
                            },
                          ]}
                        >
                          <Input
                            InputComponent={AntdInput}
                            type="text"
                            onBlur={formatPhoneNumber}
                            placeHolder={phonePlaceHolder}
                          />
                        </Form.Item>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
          <Col span={24}>
            <Checkbox
              style={{ fontWeight: '400' }}
              checked={receiveNotiByPhone}
              onChange={() => setReceiveNotiByPhone(!receiveNotiByPhone)}
            >
              <FormattedMessage {...utilsMessages.checkboxRequest} />
            </Checkbox>
          </Col>
        </Row>
      </Form>
    </PopupRequestNewProduct>
  );
};

export default withRouter(RequestNewProductPopup);
